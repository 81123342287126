import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { useParams } from 'react-router-dom';
import Spinner from './spinner/Spinner';
import { fileToBase64 } from '../helpers/convertToBase64';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

const schema = yup
  .object({
    headerCsv: yup
      .mixed()
      .required('Please select a csv file')
      .test('fileSize', 'Please upload a csv file less than 5MB', (value) => {
        return value && value[0].size <= 5000000;
      })
      .test('fileType', 'Only .csv files supported', (value) =>
        ['text/csv'].includes(value[0].type)
      ),
  })
  .required();

function OnboardStudents({ toggleModal }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { cohortId } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const queryClient = useQueryClient();
  const onStudentCreationSuccess = () => {
    toast.success('Onboarding successful! Generating DIDs may take a while.');
    toggleModal();
    setIsSubmitting(false);
  };
  const onStudentCreationFailure = () => {
    toast.error('Onboarding failed! Please try again');
    setIsSubmitting(false);
  };

  const mutation = useMutation(
    (data) => {
      return axiosInstancev2.post('/users/onboard-students', data);
    },
    {
      onSuccess: () => {
        onStudentCreationSuccess();
        queryClient.invalidateQueries(['students', cohortId]);
        queryClient.invalidateQueries(['cohorts']);
      },
      onError: onStudentCreationFailure,
    }
  );
  const onSubmit = async (data) => {
    setIsSubmitting(true);

    const base64Url = await fileToBase64(data.headerCsv[0]);
    const requestBody = {
      applicants_csv: base64Url,
      cohort_id: cohortId,
    };

    mutation.mutate(requestBody);
  };

  return (
    <div>
      {' '}
      <form
        className="text-gray-700 space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col space-y-1">
          <label htmlFor="headerCsv" className="text-base">
            Header CSV
          </label>
          <input
            {...register('headerCsv', {
              required: true,
            })}
            accept=".csv"
            type="file"
            id="headerCsv"
            className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
              errors.headerCsv ? 'border-red-600' : ''
            }`}
          />
          {errors.headerCsv && (
            <span className="text-red-600 text-xs mt-2">
              {errors.headerCsv?.message}
            </span>
          )}
        </div>
        {isSubmitting ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-between mt-8">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={toggleModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
              type="submit"
            >
              <SaveIcon fontSize="inherit" />
              <p>Onboard Students</p>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default OnboardStudents;
