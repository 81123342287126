import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useParams } from 'react-router';
import usePermissions from '../hooks/usePermissions';
import useGetUserRole from '../hooks/useGetUserRole';
import Spinner from './spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';
import tinyMCEConfigs from '../utils/tinymce';

const schema = yup
  .object({
    assignmentTitle: yup.string().required('assignment title is required'),
  })
  .required();

function EditAssignment({
  toggleModal,
  assignmentContent,
  instructions,
  assignmentTitle: topic,
}) {
  const { assignmentId } = useParams();
  const [bodyContentIsDirty, setBodyContentIsDirty] = useState(false);
  const [instructionContentIsDirty, setInstructionsContentIsDirty] =
    useState(false);
  const [assignmentBody, setAssignmentBody] = useState(assignmentContent);
  const [assignmentInstructions, setAssignmentInstructions] =
    useState(instructions);

  const permissions = usePermissions();
  const role = useGetUserRole();

  const canUpdateAssignment =
    permissions?.includes('UpdateAssignment') || role === 'SuperAdmin';

  // check if is empty

  const isBodyEmpty = assignmentBody === '<p><br></p>' || assignmentBody === '';

  const isInstructionsEmpty =
    assignmentInstructions === '<p><br></p>' || assignmentInstructions === '';

  const instructionEditorRef = useRef(null);
  const bodyEditorRef = useRef(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    setValue('assignmentTitle', topic);
  }, [topic, setValue]);

  const queryClient = useQueryClient();

  const saveBodyContent = () => {
    if (bodyEditorRef.current) {
      const content = bodyEditorRef.current.getContent();
      setBodyContentIsDirty(false);
      bodyEditorRef.current.setDirty(false);
      setAssignmentBody(content);
    }
  };

  const saveInstructionsContent = () => {
    if (instructionEditorRef.current) {
      const content = instructionEditorRef.current.getContent();
      setInstructionsContentIsDirty(false);
      instructionEditorRef.current.setDirty(false);
      setAssignmentInstructions(content);
    }
  };

  const onEditAssignmentSuccess = () => {
    queryClient.invalidateQueries(['assignment', assignmentId]);
    toast.success('Assignment updated successfully.');

    toggleModal();
  };

  const onEditAssignmentFailure = () => {
    toast.error("Couldn't update assignment. Please try again!");
  };

  const { mutate, isLoading } = useMutation(
    (data) => {
      return axiosInstancev2.put(`/assignments/${assignmentId}`, data);
    },
    { onSuccess: onEditAssignmentSuccess, onError: onEditAssignmentFailure }
  );
  const editAssignment = async (data) => {
    const requestBody = {
      title: data?.assignmentTitle,
      body: assignmentBody,
      instructions: assignmentInstructions,
    };

    mutate(requestBody);
  };

  const buttonDisabled =
    instructionContentIsDirty ||
    bodyContentIsDirty ||
    isBodyEmpty ||
    isInstructionsEmpty ||
    Object.keys(errors).length > 0;

  return (
    <form className="space-y-4" onSubmit={handleSubmit(editAssignment)}>
      <div className="flex flex-col space-y-1 w-full">
        <label htmlFor="assignmentTitle" className="text-base">
          Assignment Title
        </label>
        <input
          {...register('assignmentTitle', { required: true })}
          type="text"
          id="assignmentTitle"
          placeholder="Assignment Title"
          className="border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none"
        />
        {errors.assignmentTitle && (
          <span className="text-red-600 text-xs mt-2">
            {errors.assignmentTitle.message}
          </span>
        )}
      </div>
      <div className="space-y-2">
        <label htmlFor="assignmentInstructions" className="text-base">
          Assignment Instructions
        </label>
        <Editor
          tinymceScriptSrc="/tinymce/tinymce.min.js"
          licenseKey="gpl"
          initialValue={assignmentInstructions}
          onInit={(evt, editor) => {
            instructionEditorRef.current = editor;
          }}
          onDirty={() => setInstructionsContentIsDirty(true)}
          init={{
            ...tinyMCEConfigs,
            editable_root: canUpdateAssignment,
            save_onsavecallback: saveInstructionsContent,
          }}
        />
        {instructionContentIsDirty && (
          <p>You have unsaved assignment instructions content!</p>
        )}
      </div>
      <div className="space-y-2">
        <label htmlFor="assignmentContent" className="text-base">
          Assignment Content
        </label>
        <Editor
          tinymceScriptSrc="/tinymce/tinymce.min.js"
          licenseKey="gpl"
          initialValue={assignmentBody}
          onInit={(evt, editor) => {
            bodyEditorRef.current = editor;
          }}
          onDirty={() => setBodyContentIsDirty(true)}
          init={{
            ...tinyMCEConfigs,
            editable_root: canUpdateAssignment,
            save_onsavecallback: saveBodyContent,
          }}
        />
        {bodyContentIsDirty && <p>You have unsaved assignment body content!</p>}
      </div>
      {isLoading ? (
        <div className="mt-5 grid items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div className=" flex justify-between mt-8">
          <button
            className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
            type="button"
            onClick={toggleModal}
          >
            <CancelIcon fontSize="inherit" />
            <p>Cancel</p>
          </button>
          <button
            className={`px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center  ${
              buttonDisabled
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-claret-500'
            }`}
            type="submit"
            disabled={buttonDisabled}
          >
            <SaveIcon fontSize="inherit" />
            <p>Save Changes</p>
          </button>
        </div>
      )}
    </form>
  );
}

export default EditAssignment;
