import React, { useRef, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import usePermissions from '../hooks/usePermissions';
import useGetUserRole from '../hooks/useGetUserRole';
import Spinner from './spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';
import { fileToBase64 } from '../helpers/convertToBase64';
import tinyMCEConfigs from '../utils/tinymce';

const schema = yup
  .object({
    moduleName: yup.string().required('This is a required field'),
    moduleShortDescription: yup.string().required('This is a required field'),
    skillLevel: yup.string().required('This is a required field'),
    moduleImage: yup
      .mixed()
      .required('Please select an image')
      .test('fileSize', 'Please upload image less than 4MB', (value) => {
        if (!value || value.length === 0) {
          return true;
        }
        return value[0].size <= 4000000;
      }),
    intro_video_url: yup.string().required('This is a required field'),
  })
  .required();

function EditCourseModule({ toggleModal, module }) {
  //   const [isSubmitting, setIsSubmitting] = useState(false);
  const { moduleId } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const permissions = usePermissions();
  const role = useGetUserRole();

  const canUpdateCourseModule =
    permissions?.includes('UpdateCourseModule') || role === 'SuperAdmin';

  const queryClient = useQueryClient();
  const onModuleCreationSuccess = () => {
    toast.success('Module successfully updated!');
    toggleModal();
  };
  const onModuleCreationFailure = () => {
    toast.error("Couldn't update module! Please try again");
  };

  const { mutate, isLoading: isSubmitting } = useMutation(
    (data) => {
      return axiosInstancev2.put(`/course-modules/${moduleId}`, data);
    },
    {
      onSuccess: () => {
        onModuleCreationSuccess();
        queryClient.invalidateQueries(['module', module?.id]);
      },
      onError: onModuleCreationFailure,
    }
  );

  const [moduleDescription, setModuleDescription] = useState(
    module?.moduleDescription
  );
  const [requirements, setRequirements] = useState(module?.requirements);
  const [whatToLearn, setWhatToLearn] = useState(module?.what_to_be_learnt);
  const [technologiesToBeMastered, setTechnologiesToBeMastered] = useState(
    module?.technologies_to_be_mastered
  );

  const [descriptionIsDirty, setDescriptionIsDirty] = useState(false);
  const [requirementsIsDirty, setRequirementsIsDirty] = useState(false);
  const [whatToLearnIsDirty, setWhatToLearnIsDirty] = useState(false);
  const [technologiesToBeMasteredIsDirty, setTechnologiesToBeMasteredIsDirty] =
    useState(false);

  // wysiwyg
  const descriptionEditorRef = useRef(null);
  const requirementsEditorRef = useRef(null);
  const whatToLearnEditorRef = useRef(null);
  const technologiesEditorRef = useRef(null);

  const saveDescriptionContent = () => {
    if (descriptionEditorRef.current) {
      const content = descriptionEditorRef.current.getContent();
      setDescriptionIsDirty(false);
      descriptionEditorRef.current.setDirty(false);
      setModuleDescription(content);
    }
  };

  const saveRequirementsContent = () => {
    if (requirementsEditorRef.current) {
      const content = requirementsEditorRef.current.getContent();
      setRequirementsIsDirty(false);
      requirementsEditorRef.current.setDirty(false);
      setRequirements(content);
    }
  };

  const saveWhatToLearnContent = () => {
    if (whatToLearnEditorRef.current) {
      const content = whatToLearnEditorRef.current.getContent();
      setWhatToLearnIsDirty(false);
      whatToLearnEditorRef.current.setDirty(false);
      setWhatToLearn(content);
    }
  };
  const saveTechnologiesContent = () => {
    if (technologiesEditorRef.current) {
      const content = technologiesEditorRef.current.getContent();
      setTechnologiesToBeMasteredIsDirty(false);
      technologiesEditorRef.current.setDirty(false);
      setTechnologiesToBeMastered(content);
    }
  };

  // check if is empty
  const isDescriptionEmpty =
    moduleDescription === '<p><br></p>' || moduleDescription === '';

  const isRequirementEmpty =
    requirements === '<p><br></p>' || requirements === '';

  const isWhatToLearnEmpty =
    whatToLearn === '<p><br></p>' || whatToLearn === '';

  const isTechnologiesToBeMasteredEmpty =
    technologiesToBeMastered === '<p><br></p>' ||
    technologiesToBeMastered === '';

  // wysiwyg

  const onSubmit = async (data) => {
    const base64Url = data?.moduleImage[0]
      ? await fileToBase64(data.moduleImage[0])
      : '';

    const requestBody = {
      ...data,
      moduleImage: base64Url,
      moduleDescription,
      requirements,
      what_to_be_learnt: whatToLearn,
      technologies_to_be_mastered: technologiesToBeMastered,
    };
    mutate(requestBody);
  };

  const buttonDisabled =
    isDescriptionEmpty ||
    isRequirementEmpty ||
    isWhatToLearnEmpty ||
    isTechnologiesToBeMasteredEmpty ||
    descriptionIsDirty ||
    requirementsIsDirty ||
    whatToLearnIsDirty ||
    technologiesToBeMasteredIsDirty ||
    Object.keys(errors).length > 0;

  return (
    <div>
      <form
        className="text-gray-700 space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col space-y-1">
          <label htmlFor="moduleName" className="text-base">
            Title
          </label>
          <input
            {...register('moduleName', { required: true })}
            type="text"
            id="moduleName"
            placeholder="Module Name"
            className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
              errors.moduleName ? 'border-red-600' : ''
            }`}
            defaultValue={module?.moduleName}
          />
          {errors.moduleName && (
            <span className="text-red-600 text-xs mt-2">
              {errors.moduleName?.message}
            </span>
          )}
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col space-y-1">
            <label htmlFor="moduleImage" className="text-base">
              Module Image
            </label>
            <input
              {...register('moduleImage', {
                required: true,
              })}
              accept="image/jgp, image/jpeg,image/webp"
              type="file"
              id="moduleImage"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.moduleImage ? 'border-red-600' : ''
              }`}
            />
            {errors.moduleImage && (
              <span className="text-red-600 text-xs mt-2">
                {errors.moduleImage?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="intro_video_url" className="text-base">
              Video Url
            </label>
            <input
              {...register('intro_video_url', { required: true })}
              type="text"
              id="intro_video_url"
              placeholder="Video Url"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.intro_video_url ? 'border-red-600' : ''
              }`}
              defaultValue={module?.intro_video_url}
            />
            {errors.intro_video_url && (
              <span className="text-red-600 text-xs mt-2">
                {errors.intro_video_url?.message}
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="skillLevel" className="text-base">
            Skill Level
          </label>
          <select
            {...register('skillLevel', { required: true })}
            required
            name="skillLevel"
            id="skillLevel"
            className="border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none"
            defaultValue={module?.skillLevel}
          >
            <option value="">Select Option</option>
            <option value="beginner">Beginner</option>
            <option value="intermediate">Intermediate</option>
            <option value="advanced">Advanced</option>
          </select>
          {errors.skillLevel && (
            <span className="text-red-600 text-xs mt-2">
              {errors.skillLevel?.message}
            </span>
          )}
        </div>

        <div className="flex flex-col space-y-1">
          <label htmlFor="moduleShortDescription" className="text-base">
            Short Description
          </label>
          <textarea
            {...register('moduleShortDescription', { required: true })}
            type="text"
            id="moduleShortDescription"
            placeholder="Module Description"
            className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
              errors.moduleName ? 'border-red-600' : ''
            }`}
            rows={2}
            defaultValue={module?.moduleShortDescription}
          />
          {errors.moduleShortDescription && (
            <span className="text-red-600 text-xs mt-2">
              {errors.moduleShortDescription?.message}
            </span>
          )}
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div className="flex flex-col space-y-1 max-w-full">
            <label htmlFor="moduleDescription" className="text-base">
              Module Description
            </label>
            <Editor
              tinymceScriptSrc="/tinymce/tinymce.min.js"
              licenseKey="gpl"
              initialValue={moduleDescription}
              onInit={(evt, editor) => {
                descriptionEditorRef.current = editor;
              }}
              onDirty={() => setDescriptionIsDirty(true)}
              init={{
                ...tinyMCEConfigs,
                min_height: 300,
                max_height: 500,
                editable_root: canUpdateCourseModule,
                save_onsavecallback: saveDescriptionContent,
              }}
            />
            {descriptionIsDirty && (
              <p className="text-persian-400 text-sm">
                You have unsaved Description content!
              </p>
            )}
            {isDescriptionEmpty && (
              <span className="text-red-600 text-xs mt-2">
                Please Enter Module Description
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1 max-w-full">
            <label htmlFor="requirements" className="text-base">
              Module Requirements{' '}
              <strong className="">
                (Please enter the module prerequisites)
              </strong>
            </label>
            <Editor
              tinymceScriptSrc="/tinymce/tinymce.min.js"
              licenseKey="gpl"
              initialValue={requirements}
              onInit={(evt, editor) => {
                requirementsEditorRef.current = editor;
              }}
              onDirty={() => setRequirementsIsDirty(true)}
              init={{
                ...tinyMCEConfigs,
                min_height: 300,
                max_height: 500,
                editable_root: canUpdateCourseModule,
                save_onsavecallback: saveRequirementsContent,
              }}
            />
            {requirementsIsDirty && (
              <p className="text-persian-400 text-sm">
                You have unsaved Requirements content!
              </p>
            )}
            {isRequirementEmpty && (
              <span className="text-red-600 text-xs mt-2">
                Please Enter module Requirements
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1 max-w-full">
            <label htmlFor="whatToLearn" className="text-base">
              What You Will Learn{' '}
              <strong className="">(Enter what the student will learn)</strong>
            </label>
            <Editor
              tinymceScriptSrc="/tinymce/tinymce.min.js"
              licenseKey="gpl"
              initialValue={whatToLearn}
              onInit={(evt, editor) => {
                whatToLearnEditorRef.current = editor;
              }}
              onDirty={() => setWhatToLearnIsDirty(true)}
              init={{
                ...tinyMCEConfigs,
                min_height: 300,
                max_height: 500,
                editable_root: canUpdateCourseModule,
                save_onsavecallback: saveWhatToLearnContent,
              }}
            />
            {whatToLearnIsDirty && (
              <p className="text-persian-400 text-sm">
                You have unsaved What To Learn content!
              </p>
            )}
            {isWhatToLearnEmpty && (
              <span className="text-red-600 text-xs mt-2">
                Please Enter What To Learn
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1 max-w-full">
            <label htmlFor="technologiesToBeMastered" className="text-base">
              Technologies to be mastered{' '}
              <strong className="">
                (Please enter technologies student will master)
              </strong>
            </label>
            <Editor
              tinymceScriptSrc="/tinymce/tinymce.min.js"
              licenseKey="gpl"
              initialValue={technologiesToBeMastered}
              onInit={(evt, editor) => {
                technologiesEditorRef.current = editor;
              }}
              onDirty={() => setTechnologiesToBeMasteredIsDirty(true)}
              init={{
                ...tinyMCEConfigs,
                min_height: 300,
                max_height: 500,
                editable_root: canUpdateCourseModule,
                save_onsavecallback: saveTechnologiesContent,
              }}
            />
            {technologiesToBeMasteredIsDirty && (
              <p className="text-persian-400 text-sm">
                You have unsaved technologies to be mastered content!
              </p>
            )}
            {isTechnologiesToBeMasteredEmpty && (
              <span className="text-red-600 text-xs mt-2">
                Please Enter module technologies to be mastered
              </span>
            )}
          </div>
        </div>

        {isSubmitting ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-between mt-8">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={toggleModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className={`px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center  ${
                buttonDisabled
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-claret-500'
              }`}
              type="submit"
              disabled={buttonDisabled}
            >
              <SaveIcon fontSize="inherit" />
              <p>Update Course Module</p>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default EditCourseModule;
