import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-hot-toast';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import useGetUserRole from '../hooks/useGetUserRole';
import usePermissions from '../hooks/usePermissions';
import TableWrapper from '../utils/TableWrapper';
import AssignInstructor from './AssignInstructor';
import Modal from './Modal';
import Spinner from './spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

function Instructor({ moduleName }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [recordId, setRecordId] = useState('');
  const [instructorName, setInstructorName] = useState('');
  const permissions = usePermissions();
  const role = useGetUserRole();
  const { moduleId } = useParams();
  const queryClient = useQueryClient();

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  const columns = [
    {
      title: 'Name',
      field: 'name',
      render: (row) => (
        <p>
          {row.instructor.firstname} {row.instructor.lastname}
        </p>
      ),
    },
    {
      title: 'Email',
      field: 'email',
      render: (row) => <p>{row.instructor.email}</p>,
    },
    {
      title: 'Role',
      field: 'role',
      render: (row) => {
        if (row?.instructor.role === 'ModuleLead') {
          return <p>Module Lead</p>;
        }
        if (row?.instructor.role === 'AssistantInstructor') {
          return <p>Assistant Instructor</p>;
        }
        return <p>{row.instructor.role}</p>;
      },
    },
    {
      title: 'Actions',
      render: (row) => (
        <div className="space-x-4">
          <button
            onClick={() => {
              setRecordId(row.id);
              setInstructorName(
                `${row.instructor.firstname} ${row.instructor.lastname}`
              );
              toggleDeleteModal();
            }}
            type="button"
            className="p-1 px-2 bg-red-700 text-white rounded-md"
          >
            Remove Instructor
          </button>
        </div>
      ),
    },
  ];

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const fetchModuleLeadList = async () => {
    const data = await axiosInstancev2.get('/users/administrator', {
      params: { $limit: 50, role: 'ModuleLead' },
    });
    return data;
  };

  const fetchInstructorsList = async () => {
    const data = await axiosInstancev2.get('/users/administrator', {
      params: { $limit: 50, role: 'Instructor' },
    });
    return data;
  };

  const fetchAssistantInstructorsList = async () => {
    const data = await axiosInstancev2.get('/users/administrator', {
      params: { $limit: 50, role: 'AssistantInstructor' },
    });
    return data;
  };

  const { data: moduleLeadsList } = useQuery(
    ['moduleleads'],
    fetchModuleLeadList,
    {
      staleTime: 3600 * 10000,
      cacheTime: 3600 * 10000,
    }
  );

  const { data: users } = useQuery(['instructors'], fetchInstructorsList, {
    staleTime: 3600 * 10000,
    cacheTime: 3600 * 10000,
  });

  const { data: assistants } = useQuery(
    ['assistantinstructors'],
    fetchAssistantInstructorsList,
    {
      staleTime: 3600 * 10000,
      cacheTime: 3600 * 10000,
    }
  );
  const moduleLeads =
    moduleLeadsList &&
    moduleLeadsList?.data?.administrators?.map((moduleLead) => ({
      value: moduleLead.id,
      label: `${moduleLead.firstname} ${moduleLead.lastname}`,
      role: moduleLead.role,
    }));

  const instructors =
    users &&
    users?.data?.administrators?.map((instructor) => ({
      value: instructor.id,
      label: `${instructor.firstname} ${instructor.lastname}`,
      role: instructor.role,
    }));

  const assistantInstructors =
    assistants &&
    assistants?.data?.administrators?.map((assistant) => ({
      value: assistant.id,
      label: `${assistant.firstname} ${assistant.lastname}`,
      role: assistant.role,
    }));

  const fetchModuleInstructors = async () => {
    const response = await axiosInstancev2.get(
      `/module-instructors?module_id=${moduleId}`
    );
    return response;
  };

  const { data, isLoading } = useQuery(
    ['module-instructors', moduleId],
    fetchModuleInstructors,
    {
      enabled: !!moduleId,
      staleTime: 3600 * 1000,
      cacheTime: 3600 * 1000,
    }
  );

  const onInstructorDeletionSuccess = () => {
    toast.success('Instructor successfuly removed');
    toggleDeleteModal();
    queryClient.invalidateQueries(['module-instructors', moduleId]);
  };

  const onInstructorDeletionError = () => {
    toast.error("Couldn't remove instructor. Please try again");
  };

  const { mutate, isLoading: deletingQuiz } = useMutation(
    () => {
      return axiosInstancev2.delete(`/module-instructors/${recordId}`);
    },
    {
      onSuccess: onInstructorDeletionSuccess,
      onError: onInstructorDeletionError,
    }
  );

  const deleteQuizHandler = () => {
    mutate();
  };

  return (
    <div className="space-y-2">
      <div className=" flex justify-end">
        {(permissions?.includes('CreateModuleInstructor') ||
          role === 'SuperAdmin') && (
          <button
            type="button"
            className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
            onClick={toggleModal}
          >
            Assign Instructors
          </button>
        )}
      </div>
      <div className="mt-4">
        {isLoading ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <TableWrapper
            title="Instructors for this module"
            columns={columns}
            data={data ? data?.data?.module_instructors : []}
          />
        )}
      </div>
      <Modal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        title={`Assign Instructor to ${moduleName}`}
      >
        <AssignInstructor
          moduleLeads={moduleLeads}
          toggleModal={toggleModal}
          instructors={instructors}
          assistantInstructors={assistantInstructors}
          moduleInstructors={data?.data?.administrators || []}
        />
      </Modal>
      <Modal title="Remove Instructor" modalOpen={deleteModalOpen}>
        <p>
          Are you sure you want to remove{' '}
          <span className="font-medium">{instructorName}</span> from this
          module??
        </p>

        {deletingQuiz ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-between mt-10">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={toggleDeleteModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
              type="button"
              onClick={deleteQuizHandler}
            >
              <DeleteIcon fontSize="inherit" />
              <p>Remove Instructor</p>
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default Instructor;
