/* eslint-disable no-shadow */
import React, { useState, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import SaveIcon from '@mui/icons-material/Save';
// import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import usePermissions from '../hooks/usePermissions';
import useGetUserRole from '../hooks/useGetUserRole';
import Spinner from './spinner/Spinner';
import { fileToBase64 } from '../helpers/convertToBase64';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';
import tinyMCEConfigs from '../utils/tinymce';

const schema = yup
  .object({
    moduleName: yup.string().required('This is a required field'),
    moduleShortDescription: yup.string().required('This is a required field'),
    intro_video_url: yup.string().required('This is a required field'),
    skillLevel: yup.string().required('This is a required field'),
    is_paid_module: yup.string().required('This is a required field'),
    category: yup.string().required('This is a required field'),
    usd_principal_price: yup
      .string()
      .when('is_paid_module', (is_paid_module, schema) => {
        if (is_paid_module === 'yes')
          return schema.required('This is a required field');
        return schema;
      }),
    percentage_discount: yup
      .string()
      .when('is_paid_module', (is_paid_module, schema) => {
        if (is_paid_module === 'yes')
          return schema.required('This is a required field');
        return schema;
      }),
    headerImage: yup
      .mixed()
      .required('Please select an image')
      .test('fileSize', 'Please upload image less than 2MB', (value) => {
        return value && value[0].size <= 2000000;
      }),
  })
  .required();

function CreateModule() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { courseId } = useParams();

  const [moduleDescription, setModuleDescription] = useState('');
  const [requirements, setRequirements] = useState('');
  const [whatToLearn, setWhatToLearn] = useState('');
  const [technologiesToBeMastered, setTechnologiesToBeMastered] = useState('');

  const [descriptionIsDirty, setDescriptionIsDirty] = useState(false);
  const [requirementsIsDirty, setRequirementsIsDirty] = useState(false);
  const [whatToLearnIsDirty, setWhatToLearnIsDirty] = useState(false);
  const [technologiesToBeMasteredIsDirty, setTechnologiesToBeMasteredIsDirty] =
    useState(false);

  const permissions = usePermissions();
  const role = useGetUserRole();

  const canCreateCourseModule =
    permissions?.includes('CreateCourseModule') || role === 'SuperAdmin';

  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const isPaidModuleFieldValue = watch('is_paid_module');

  const queryClient = useQueryClient();
  const onModuleCreationSuccess = () => {
    toast.success('Course Module successfully created!');
    setIsSubmitting(false);
    reset();
    setModuleDescription('');
    setRequirements('');
    setWhatToLearn('');
    setTechnologiesToBeMastered('');
  };
  const onModuleCreationFailure = () => {
    toast.error('Course Module creation failed! Please try again');
    setIsSubmitting(false);
  };

  const mutation = useMutation(
    (data) => {
      return axiosInstancev2.post('/course-modules', data);
    },
    {
      onSuccess: () => {
        onModuleCreationSuccess();
        queryClient.invalidateQueries(['course-modules', courseId]);
      },
      onError: onModuleCreationFailure,
    }
  );

  // wysiwyg
  const descriptionEditorRef = useRef(null);
  const requirementsEditorRef = useRef(null);
  const whatToLearnEditorRef = useRef(null);
  const technologiesEditorRef = useRef(null);

  const saveDescriptionContent = () => {
    if (descriptionEditorRef.current) {
      const content = descriptionEditorRef.current.getContent();
      setDescriptionIsDirty(false);
      descriptionEditorRef.current.setDirty(false);
      setModuleDescription(content);
    }
  };

  const saveRequirementsContent = () => {
    if (requirementsEditorRef.current) {
      const content = requirementsEditorRef.current.getContent();
      setRequirementsIsDirty(false);
      requirementsEditorRef.current.setDirty(false);
      setRequirements(content);
    }
  };

  const saveWhatToLearnContent = () => {
    if (whatToLearnEditorRef.current) {
      const content = whatToLearnEditorRef.current.getContent();
      setWhatToLearnIsDirty(false);
      whatToLearnEditorRef.current.setDirty(false);
      setWhatToLearn(content);
    }
  };
  const saveTechnologiesContent = () => {
    if (technologiesEditorRef.current) {
      const content = technologiesEditorRef.current.getContent();
      setTechnologiesToBeMasteredIsDirty(false);
      technologiesEditorRef.current.setDirty(false);
      setTechnologiesToBeMastered(content);
    }
  };
  // check if is empty

  const isDescriptionEmpty =
    moduleDescription === '<p><br></p>' || moduleDescription === '';

  const isRequirementEmpty =
    requirements === '<p><br></p>' || requirements === '';

  const isWhatToLearnEmpty =
    whatToLearn === '<p><br></p>' || whatToLearn === '';

  const isTechnologiesToBeMasteredEmpty =
    technologiesToBeMastered === '<p><br></p>' ||
    technologiesToBeMastered === '';

  // wysiwyg

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    const base64Url = await fileToBase64(data.headerImage[0]);
    const requestBody = {
      ...data,
      moduleImage: base64Url,
      currency: 'USD',
      courseId,
      moduleDescription,
      requirements,
      what_to_be_learnt: whatToLearn,
      usd_principal_price: Number(
        parseFloat(data?.usd_principal_price).toFixed(2)
      ),
      percentage_discount: Number(
        parseFloat(data?.percentage_discount).toFixed(2)
      ),
      is_published: false,
      is_paid_module: data.is_paid_module === 'yes' || false,
      technologies_to_be_mastered: technologiesToBeMastered,
    };

    mutation.mutate(requestBody);
  };

  const buttonDisabled =
    isDescriptionEmpty ||
    isRequirementEmpty ||
    isWhatToLearnEmpty ||
    isTechnologiesToBeMasteredEmpty ||
    descriptionIsDirty ||
    requirementsIsDirty ||
    whatToLearnIsDirty ||
    technologiesToBeMasteredIsDirty ||
    Object.keys(errors).length > 0;

  return (
    <div className="mt-4 p-2 bg-white">
      <h1 className="font-semibold text-xl mb-4">Create New Module</h1>
      <form
        className="text-gray-700 space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col space-y-1">
            <label htmlFor="moduleName" className="text-base">
              Title
            </label>
            <input
              {...register('moduleName', { required: true })}
              type="text"
              id="moduleName"
              placeholder="Module Name"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.moduleName ? 'border-red-600' : ''
              }`}
            />
            {errors.moduleName && (
              <span className="text-red-600 text-xs mt-2">
                {errors.moduleName?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="category" className="text-base">
              Module Category
            </label>
            <select
              {...register('category', { required: true })}
              required
              name="category"
              id="category"
              className="border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none"
            >
              <option value="">Select Category</option>
              <option value="Front-End">Front-End</option>
              <option value="Back-End">Back-End</option>
              <option value="Data Analytics">Data Analytics</option>
              <option value="Artificial Intelligence">
                Artificial Intelligence
              </option>
              <option value="Cyber Security">Cyber Security</option>
              <option value="Entrepreneurship">Entrepreneurship</option>
            </select>
            {errors.category && (
              <span className="text-red-600 text-xs mt-2">
                {errors.category?.message}
              </span>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col space-y-1">
            <label htmlFor="intro_video_url" className="text-base">
              Video Url
            </label>
            <input
              {...register('intro_video_url', { required: true })}
              type="text"
              id="intro_video_url"
              placeholder="Video Url"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.intro_video_url ? 'border-red-600' : ''
              }`}
            />
            {errors.intro_video_url && (
              <span className="text-red-600 text-xs mt-2">
                {errors.intro_video_url?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="headerImage" className="text-base">
              Header Image
            </label>
            <input
              {...register('headerImage', {
                required: true,
              })}
              accept="image/gif, image/jpeg"
              type="file"
              id="headerImage"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.headerImage ? 'border-red-600' : ''
              }`}
            />
            {errors.headerImage && (
              <span className="text-red-600 text-xs mt-2">
                {errors.headerImage?.message}
              </span>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col space-y-1">
            <label htmlFor="skillLevel" className="text-base">
              Skill Level
            </label>
            <select
              {...register('skillLevel', { required: true })}
              required
              name="skillLevel"
              id="skillLevel"
              className="border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none"
            >
              <option value="">Select Option</option>
              <option value="beginner">Beginner</option>
              <option value="intermediate">Intermediate</option>
              <option value="advanced">Advanced</option>
            </select>
            {errors.skillLevel && (
              <span className="text-red-600 text-xs mt-2">
                {errors.skillLevel?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="is_paid_module" className="text-base">
              Paid Module
            </label>
            <select
              {...register('is_paid_module', { required: true })}
              required
              name="is_paid_module"
              id="is_paid_module"
              className="border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none"
            >
              <option value="">Select Option</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
            {errors.is_paid_module && (
              <span className="text-red-600 text-xs mt-2">
                {errors.is_paid_module?.message}
              </span>
            )}
          </div>
        </div>
        {isPaidModuleFieldValue === 'yes' && (
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col space-y-1">
              <label htmlFor="usd_principal_price" className="text-base">
                Price (USD)
              </label>
              <input
                {...register('usd_principal_price', { required: true })}
                type="number"
                min={0}
                step={0.01}
                id="usd_principal_price"
                placeholder="0.00"
                className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                  errors.usd_principal_price ? 'border-red-600' : ''
                }`}
              />
              {errors.usd_principal_price && (
                <span className="text-red-600 text-xs mt-2">
                  {errors.usd_principal_price?.message}
                </span>
              )}
            </div>
            <div className="flex flex-col space-y-1">
              <label htmlFor="percentage_discount" className="text-base">
                Percentage Discount (% )
              </label>
              <input
                {...register('percentage_discount', { required: true })}
                type="number"
                min={0}
                step={0.01}
                max={100}
                id="percentage_discount"
                placeholder="0.0%"
                className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                  errors.percentage_discount ? 'border-red-600' : ''
                }`}
              />
              {errors.percentage_discount && (
                <span className="text-red-600 text-xs mt-2">
                  {errors.percentage_discount?.message}
                </span>
              )}
            </div>
          </div>
        )}
        <div className="flex flex-col space-y-1">
          <label htmlFor="moduleShortDescription" className="text-base">
            Short Description
          </label>
          <textarea
            {...register('moduleShortDescription', { required: true })}
            type="text"
            id="moduleShortDescription"
            placeholder="Module Short Description"
            className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
              errors.moduleName ? 'border-red-600' : ''
            }`}
            rows={2}
          />
          {errors.moduleShortDescription && (
            <span className="text-red-600 text-xs mt-2">
              {errors.moduleShortDescription?.message}
            </span>
          )}
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col space-y-1 max-w-full">
            <label htmlFor="moduleDescription" className="text-base">
              Module Description
            </label>
            <Editor
              tinymceScriptSrc="/tinymce/tinymce.min.js"
              licenseKey="gpl"
              initialValue={moduleDescription}
              onInit={(evt, editor) => {
                descriptionEditorRef.current = editor;
              }}
              onDirty={() => setDescriptionIsDirty(true)}
              init={{
                ...tinyMCEConfigs,
                min_height: 300,
                max_height: 500,
                editable_root: canCreateCourseModule,
                save_onsavecallback: saveDescriptionContent,
              }}
            />
            {descriptionIsDirty && (
              <p className="text-persian-400 text-sm">
                You have unsaved Description content!
              </p>
            )}
            {isDescriptionEmpty && (
              <span className="text-red-600 text-xs mt-2">
                Please Enter Module Description
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1 max-w-full">
            <label htmlFor="requirements" className="text-base">
              Module Requirements{' '}
              <strong className="">
                (Please enter the module prerequisites)
              </strong>
            </label>
            <Editor
              tinymceScriptSrc="/tinymce/tinymce.min.js"
              licenseKey="gpl"
              initialValue={requirements}
              onInit={(evt, editor) => {
                requirementsEditorRef.current = editor;
              }}
              onDirty={() => setRequirementsIsDirty(true)}
              init={{
                ...tinyMCEConfigs,
                min_height: 300,
                max_height: 500,
                editable_root: canCreateCourseModule,
                save_onsavecallback: saveRequirementsContent,
              }}
            />
            {requirementsIsDirty && (
              <p className="text-persian-400 text-sm">
                You have unsaved Requirements content!
              </p>
            )}
            {isRequirementEmpty && (
              <span className="text-red-600 text-xs mt-2">
                Please Enter module Requirements
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1 max-w-full">
            <label htmlFor="whatToLearn" className="text-base">
              What You Will Learn{' '}
              <strong className="">(Enter what the student will learn)</strong>
            </label>
            <Editor
              tinymceScriptSrc="/tinymce/tinymce.min.js"
              licenseKey="gpl"
              initialValue={whatToLearn}
              onInit={(evt, editor) => {
                whatToLearnEditorRef.current = editor;
              }}
              onDirty={() => setWhatToLearnIsDirty(true)}
              init={{
                ...tinyMCEConfigs,
                min_height: 300,
                max_height: 500,
                editable_root: canCreateCourseModule,
                save_onsavecallback: saveWhatToLearnContent,
              }}
            />
            {whatToLearnIsDirty && (
              <p className="text-persian-400 text-sm">
                You have unsaved What To Learn content!
              </p>
            )}
            {isWhatToLearnEmpty && (
              <span className="text-red-600 text-xs mt-2">
                Please Enter What To Learn
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1 max-w-full">
            <label htmlFor="technologiesToBeMastered" className="text-base">
              Technologies to be mastered{' '}
              <strong className="">
                (Please enter technologies student will master)
              </strong>
            </label>
            <Editor
              tinymceScriptSrc="/tinymce/tinymce.min.js"
              licenseKey="gpl"
              initialValue={technologiesToBeMastered}
              onInit={(evt, editor) => {
                technologiesEditorRef.current = editor;
              }}
              onDirty={() => setTechnologiesToBeMasteredIsDirty(true)}
              init={{
                ...tinyMCEConfigs,
                min_height: 300,
                max_height: 500,
                editable_root: canCreateCourseModule,
                save_onsavecallback: saveTechnologiesContent,
              }}
            />
            {technologiesToBeMasteredIsDirty && (
              <p className="text-persian-400 text-sm">
                You have unsaved technologies to be mastered content!
              </p>
            )}
            {isTechnologiesToBeMasteredEmpty && (
              <span className="text-red-600 text-xs mt-2">
                Please Enter module technologies to be mastered
              </span>
            )}
          </div>
        </div>

        {isSubmitting ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-end mt-8">
            <button
              className={`px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center  ${
                buttonDisabled
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-claret-500'
              }`}
              type="submit"
              disabled={buttonDisabled}
            >
              <SaveIcon fontSize="inherit" />
              <p>Create Course Module</p>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default CreateModule;
